import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import Container from "../presentation/Container";
import Funding from "../../assets/images/funding.png";

const PaymentRecived = () => (
  <Wrapper>
    <Container>
      
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Steps>
            <Icon>
            <img src={Funding} alt="Applynow" />
            </Icon>
            <Typography variant="h3">Thank you We recived your payment..</Typography>
           
          </Steps>
        </Grid>
      </Grid>
    </Container>
  </Wrapper>
);

export default PaymentRecived;

const Wrapper = styled.div`
  padding: 70px 0;
  position: relative;
  background: #f7f9fb;

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  .MuiButton-root {
    margin-top: 30px;
  }
`;

const Steps = styled.div`
  padding: 40px 20px;
  box-shadow: 0px 5px 18px rgb(0 0 0 / 10%);
  border-radius: 10px;
  background: #fff;
  text-align: center;
  position: relative;
  transition: 0.3s ease-in-out all;
  height: 100%;

  h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;

    @media (max-width: 992px) {
      font-size: 18px;
    }
  }
`;


const Icon = styled.div`
  margin-bottom: 30px;

  img {
    max-height: 60px;
  }
`;
