import AssignmentForm from "../components/AssignmentForm";
import Banner from "../components/Banner";
import Faqs from "../components/Faqs";
import HowItWork from "../components/HowItWork";
import Testimonials from "../components/Testimonials";
import WhyChooseUs from "../components/WhyChooseUs";

const Home = () => (
  <>
    <Banner />
    <AssignmentForm ishomepage={true} />
    <WhyChooseUs />
    <HowItWork />
    <Faqs />
    <Testimonials />
  </>
);

export default Home;
