import { Button, Grid, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import Container from "../presentation/Container";
import Contactus from "../../assets/images/contact-us.jpg";

const ContactForm = ({ ishomepage }) => {
  return (
    <Wrapper ishomepage={ishomepage}>
      <Container>
        <ContactInfo>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <img src={Contactus} alt="Contactus" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Inner>
                <Typography variant="h4" component="h2">
                  Get in Touch
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-disabled"
                      InputLabelProps={{
                        shrink: true,
                        htmlFor: "Full Name",
                      }}
                      label="Full Name"
                      placeholder="Enter your name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-disabled"
                      InputLabelProps={{
                        shrink: true,
                        htmlFor: "Email",
                      }}
                      label="Email"
                      placeholder="Enter email address"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="outlined-disabled"
                      InputLabelProps={{
                        shrink: true,
                        htmlFor: "Subject",
                      }}
                      label="Subject"
                      placeholder="Enter Subject"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Message"
                      InputLabelProps={{
                        shrink: true,
                        htmlFor: "message",
                      }}
                      multiline
                      rows={4}
                      placeholder="Enter message"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ActionContainer>
                      <Button variant="contained" color="primary">
                        Send Feedback
                      </Button>
                    </ActionContainer>
                  </Grid>
                </Grid>
              </Inner>
            </Grid>
          </Grid>
        </ContactInfo>
      </Container>
    </Wrapper>
  );
};

export default ContactForm;

const Wrapper = styled.div`
  padding: 70px 0;
  text-align: center;
  border-radius: 0 0 50px 0;

  @media (max-width: 992px) {
    padding: 50px 0;
  }

  h2.MuiTypography-root {
    font-weight: 600;
    font-size: 22px;
    background: #43a759;
    padding: 15px;
    color: #fff;
    margin: -40px -40px 50px;
    border-radius: 10px 10px 0 0;

    @media (max-width: 992px) {
      font-size: 24px;
      margin: -30px -30px 30px;
    }

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
`;

const Inner = styled.div`
  background: rgb(33 165 167 / 2%);
  padding: 40px;
  border-radius: 10px;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 0 1px 10px rgb(0 0 0 / 10%);

  @media (max-width: 992px) {
    padding: 30px;
  }

  .MuiTextField-root {
    width: 100%;
    text-align: left;
  }
`;

const ActionContainer = styled.div`
  .MuiButton-root {
    min-width: 300px !important;
  }
  .MuiTypography-root {
    margin: 15px 0;
  }
`;

const ContactInfo = styled.div`
 
`;
