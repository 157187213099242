import { AppBar, Hidden, Link, MenuItem, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import logoImg from "../../assets/images/logo.png";
import { useState } from "react";

const Header = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <HeaderBar position="static">
      <Toolbar>
        <Logo href="/">
          <img src={logoImg} alt="logo" />
        </Logo>
        <Navigation className={open && "isopened"}>
          <Hidden mdUp>
            <MenuButton className={open && "isopened"} onClick={handleClick}>
              <CloseIcon />
            </MenuButton>
          </Hidden>
          <MenuItem>
            <Link href="/">Home</Link>
          </MenuItem>
          <MenuItem>
            <Link href="/about-us">About us</Link>
          </MenuItem>
          <MenuItem>
            <Link href="/faq">FAQ</Link>
          </MenuItem>
          <MenuItem>
            <Link href="/payment">Pay Now</Link>
          </MenuItem>
          <MenuItem>
            <Link href="/contact-us">Contact us</Link>
          </MenuItem>
        </Navigation>
        <Hidden mdUp>
          <MenuButton onClick={handleClick}>
            <MenuIcon />
          </MenuButton>
        </Hidden>
      </Toolbar>
    </HeaderBar>
  );
};

export default Header;

const HeaderBar = styled(AppBar)`
  &.MuiAppBar-root {
    padding: 12px 0;
    box-shadow: none;
    background-color: #1f2a62;
    position: sticky;
    top: 0;
    z-index: 99;

    .MuiToolbar-root {
      max-width: 1270px;
      justify-content: space-between;
      margin: 0 auto;
      width: 100%;
      padding: 0 15px;
    }
  }
`;

const Logo = styled(Link)`
  img {
    height: 60px;

    @media (max-width: 899px) {
      height: 50px;
    }
  }
`;

const Navigation = styled.nav`
  display: flex;

  @media (max-width: 899px) {
    position: fixed;
    width: 320px;
    right: 0;
    top: 0;
    bottom: 0;
    background: #21a5a7;
    z-index: 99;
    display: flex;
    flex-direction: column;
    padding: 60px 0 20px 0;
    transform: translateX(100%);
    transition: 0.3s all ease;

    &.isopened {
      display: block;
      transform: translateX(0);
    }
  }

  .MuiMenuItem-root {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    padding: 0 20px;

    @media (max-width: 899px) {
      padding: 10px 20px;
      border-bottom: 1px solid #57b1b3;
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: #33d4d6;
      }
    }
  }
`;

const MenuButton = styled.div`
  &.isopened {
    position: absolute;
    right: 18px;
    top: 18px;
  }

  .MuiSvgIcon-root {
    font-size: 40px;
    cursor: pointer;
  }
`;
