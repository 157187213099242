import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "../presentation/Container";
import Heading from "../presentation/Heading";

const Faqs = () => (
  <Wrapper>
    <Container>
      <Heading centered={true} title="Frequently Asked Questions" />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography> CAN YOU HELP ME IN ANY ASSIGNMENT? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, We can help you in any kind of assignment or project you wish
            us to complete for you.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography> WHAT IS YOUR ORDERING & SOLUTION PROCESS? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ol>
            <li>
              Just go on order now/ submit form, fill details and send us . You
              will receive an update from us within 2 hours with the price
              quote.
            </li>
            <li>
              If you are satisfied with our quote, you can confirm the
              assignment by making a payment.
            </li>
            <li>
              Once the assignment is confirmed, we will send you the completed
              assignment before deadline via email.
            </li>
          </ol>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography> WHAT KIND OF ASSIGNMENT CAN YOU MAKE? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We can help you in Assignment Writing, Report Writing, Essay
            Writing, Thesis, Dissertation Writing, Literature Writing, Homework
            Writing
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography> HOW CAN I MAKE THE PAYMENT FOR YOUR WORK? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can use any credit/debit cards or your PayPal account to make a
            payment for our work.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography> HOW MUCH WILL YOUR WORK COST ME? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our cost depends upon the subject, academic level, difficulty,
            urgency and resources provided. We need to evaluate your
            requirements first before we can give you a quote. Please send us
            your requirements by filling Order Now form.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  </Wrapper>
);

export default Faqs;

const Wrapper = styled.div`
  padding: 70px 0;
  position: relative;

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  > div {
    max-width: 1000px;
  }

  .MuiAccordion-root {
    margin-bottom: 15px;
    box-shadow: 0 0 0 0.05rem rgb(8 60 130 / 6%), 0 0 1.25rem rgb(30 34 40 / 4%);
    border-radius: 5px;
    background: #e8f6f6;

    &:before {
      display: none;
    }

    ul, ol {
      li {
        margin-bottom: 10px;
        
        @media (max-width: 992px) {
        font-size: 14px !important;
        }
      }
    }

    .MuiAccordionSummary-root {
      min-height: 56px;
      font-size: 15px;
      color: #000;

      &.Mui-expanded {
        border-bottom: 1px solid #c9e3e3;
      }

      .MuiTypography-root {
        color: #1f2a62;
        font-weight: 500;
      }

      .MuiAccordionSummary-expandIconWrapper {
        color: #21a5a7;
      }
    }
  }
`;
