import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import Container from "../presentation/Container";
import Heading from "../presentation/Heading";
import Applynow from "../../assets/images/completed-task.png";
import Rating from "../../assets/images/rating.png";
import Funding from "../../assets/images/funding.png";

const HowItWork = () => (
  <Wrapper>
    <Container>
      <Heading
        centered={true}
        title="How it Works"
        subtitle="We provide online Assignment with quick approval that suit your term"
      />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Steps>
            <Icon>
              <img src={Applynow} alt="Applynow" />
            </Icon>
            <Number component="span">1</Number>
            <Typography variant="h3">Apply for Assignment</Typography>
            <Typography>
              We carry more than just good coding skills. Our experience makes
              us stand out from other web development.
            </Typography>
          </Steps>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Steps>
            <Icon>
            <img src={Rating} alt="Applynow" />
            </Icon>
            <Number component="span">2</Number>
            <Typography variant="h3">Application review</Typography>
            <Typography>
              We will customize a Assignment based on the amount of your
              collage/school need term
            </Typography>
          </Steps>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Steps>
            <Icon>
            <img src={Funding} alt="Applynow" />
            </Icon>
            <Number component="span">3</Number>
            <Typography variant="h3">Get funding fast</Typography>
            <Typography>
              We will customize a Assignment based on the amount of your
              collage/school need term
            </Typography>
          </Steps>
        </Grid>
      </Grid>
    </Container>
  </Wrapper>
);

export default HowItWork;

const Wrapper = styled.div`
  padding: 70px 0;
  position: relative;
  background: #f7f9fb;

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  .MuiButton-root {
    margin-top: 30px;
  }
`;

const Steps = styled.div`
  padding: 40px 20px;
  box-shadow: 0px 5px 18px rgb(0 0 0 / 10%);
  border-radius: 10px;
  background: #fff;
  text-align: center;
  position: relative;
  transition: 0.3s ease-in-out all;
  height: 100%;

  h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;

    @media (max-width: 992px) {
      font-size: 18px;
    }
  }
`;

const Number = styled(Typography)`
  &.MuiTypography-root {
    display: block;
    font-size: 80px;
    line-height: 1;
    font-weight: 800;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #e3f7f7;
  }
`;

const Icon = styled.div`
  margin-bottom: 30px;

  img {
    max-height: 60px;
  }
`;
