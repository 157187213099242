import { Typography } from "@mui/material";
import Cms from "../presentation/Cms";
import InnerBanner from "../presentation/InnerBanner";

const RefundPolicy = () => (
  <>
    <InnerBanner iscms={true} pageTitle="Refund Policy"></InnerBanner>
    <Cms>
      <Typography variant="h3">Refund and cancellation policy</Typography>
      <Typography>
        <strong>
        30% amount is part of the services that we provide for you like checking files to tell or inform you word counts, preparing a front page, opening your portal to download requirement files and study materials, sharing feedback or messages to the writer, conducting Google meet with writer, and presentation of the report/file. Therefore, 30% of the amount is not refunded to the client.
        </strong>
      </Typography>
      <Typography variant="h4">Return coverage</Typography>

      <Typography>In case of a fail in an assignment, we refund you 70% of the paid amount. This is to inform you that the terms and conditions of refunds are as below:</Typography>
      <ul>
        <li>
        If you have not sent any feedback and have not edited the solution file in a single time and sent valid proof of fail like a PDF fail report with professor comments otherwise marks/grade on the portal in image form, we will refund you 70% of the paid amount.
        </li>
        <li>
        If you sent 2 times feedbacks and we provided you updated files 2 times including adding the student name, student ID, email on the front page, and professor/supervisor comments then we will refund you only 50% of the paid amount.
        </li>
        <li>
        If you sent feedback more than 3 times and we updated more than 3 times including adding the student name, student ID, email on the front page, and professor/supervisor comments, and provided you resist solution file then we will refund you only 30% of the paid amount.
        </li>
        <li>
        Dissertation will not Refundable, it will only redo as per feedback , Just improvement 
        </li>
        <li>
        Work cannot be cancel after start
        </li>
      </ul>
      <Typography>
        <strong>Important Note: </strong> It is compulsory/necessary for you to provide valid proof of a fail like a PDF fail report with professor comments otherwise marks/grade on the portal in image form we do not consider only text a fail and refund request. 
       
        </Typography>
    </Cms>
  </>
);

export default RefundPolicy;
