import PaymentForm from "../components/PaymentForm";
import InnerBanner from "../presentation/InnerBanner";

const Payment = () => (
  <>
    <InnerBanner pageTitle="Payment"></InnerBanner>
    <PaymentForm />
  </>
);

export default Payment;
