import { Typography } from "@mui/material";
import styled, { css } from "styled-components";

const Heading = ({ centered, title, subtitle }) => (
  <SectionTitle centered={centered}>
    <Typography variant="h4" component="h2">
      {title}
    </Typography>
    <Typography>{subtitle}</Typography>
  </SectionTitle>
);

export default Heading;

const SectionTitle = styled.div`
  ${({ centered }) => css`
    margin: 0 auto 40px auto;
    text-align: ${centered ? "center" : "left"};
    max-width: 800px;

    @media (max-width: 992px) {
      margin: 0 auto 30px auto;
    }

    h2 {
      font-weight: 600;
      font-size: 40px;

      @media (max-width: 992px) {
        font-size: 30px;
      }
      @media (max-width: 576px) {
        font-size: 24px;

        br {
          display: none;
        }
      }
    }

    .MuiTypography-root {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  `}
`;
