import { Typography } from "@mui/material";
import styled, { css } from "styled-components";
import Container from "./Container";

const InnerBanner = ({ pageTitle, iscms }) => (
  <Section iscms={iscms}>
    <Container>
      <Typography variant="h4" component="h1">
        {pageTitle}
      </Typography>
    </Container>
  </Section>
);

export default InnerBanner;

const Section = styled.div`
  ${({ iscms }) => css`
    padding: ${iscms ? "60px 0 160px 0" : "60px 0"};
    background: rgb(31 42 98);
    border-radius: 0 0 50px 0;

    h1 {
      font-size: 50px;
      font-weight: 600;
      color: #fff;
      text-align: center;
    }

    @media (max-width: 992px) {
      padding: ${iscms ? "40px 0 100px 0" : "40px 0"};

      h1 {
        font-size: 32px;
      }
    }
  `}
`;
