import Layout from "./components/global/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import AboutUs from "./components/pages/AboutUs";
import PageNotFound from "./components/pages/PageNotFound";
import Payment from "./components/pages/Payment";
import Faq from "./components/pages/Faq";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsConditions from "./components/pages/TermsConditions";
import RefundPolicy from "./components/pages/RefundPolicy";
import ContactUs from "./components/pages/ContactUs";
import PaymentSuccess from "./components/pages/PaymentSuccess";
import PaymentCancel from "./components/pages/PaymentCancel";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="payment" element={<Payment />} />
        <Route path="payment-success" element={<PaymentSuccess />} />
        <Route path="payment-cancel" element={<PaymentCancel />} />
        <Route path="faq" element={<Faq />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-conditions" element={<TermsConditions />} />
        <Route path="refund-policies" element={<RefundPolicy />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default App;
