import { Typography } from "@mui/material";
import Cms from "../presentation/Cms";
import InnerBanner from "../presentation/InnerBanner";

const TermsConditions = () => (
  <>
    <InnerBanner iscms={true} pageTitle="Terms Conditions"></InnerBanner>
    <Cms>
      <Typography>
        <strong>
          The terms and conditions of our services are required to be complied
          with by the client/student/person who wants to avail of our services.
          We do not don provide services if a person has any kind of
          disagreement with our terms of service. The terms of our services are
          disclosed below:
        </strong>
      </Typography>
      <ul>
        <li>
          The minimum age of the client must be 10 years or above as it ensures
          that you are capable of taking sound decisions on your own.
        </li>
        <li>
          To avail of our services the client must place an order by visiting
          our website (the link to the website is given by the team member in
          contact with you)
        </li>
        <li>
          An advance payment of 50% of the agreed amount is required to be made
          by the client/student to indicate their confirmation to avail of our
          writing services. The payment is required to be made by clicking on
          the payment link given to the client by our team
        </li>

        <li>
          The client/student is required to make confirmation of payment to our
          team after payment by sending a screenshot to the team members they
          have contacted.
        </li>
        <li>
          The details like the due date of the assignment, expected word count,
          and specific conditions must be specified to our team members dealing
          with you. In any case modification in these areas is required then you
          should inform our team in advance.
        </li>
        <li>
          The client/student must read the terms of services mentioned on our
          website before contacting us for the services.
        </li>
        <li>
          Once the client has made payment and submitted the assignment details
          it will be considered their willful agreement to the terms of service.
        </li>
      </ul>
    </Cms>
  </>
);

export default TermsConditions;
