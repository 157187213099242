import React, { useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import Container from "../presentation/Container";
import axios from "axios";


const PaymentForm = ({ ishomepage }) => {
  const currencies = [
    {
      value: "USD",
      label: "USD",
    },
    {
      value: "EUR",
      label: "EUR",
    },
    {
      value: "GBP",
      label: "GBP",
    },
    {
      value: "CAD",
      label: "CAD",
    },
    {
      value: "AUD",
      label: "AUD",
    },
    {
      value: "NZD",
      label: "NZD",
    },
    // {
    //   value: "INR",
    //   label: "INR",
    // },
  ];

  const exmonths = [
  {
    value: "1",
    label: "January",
  },
  {
    value: "2",
    label: "February",
  },
  {
    value: "3",
    label: "March",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "May",
  },
  {
    value: "6",
    label: "June",
  },
  {
    value: "7",
    label: "July",
  },
  {
    value: "8",
    label: "August",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  }
];

  const exyear = [
    {
      value: "2023",
      label: "2023",
    },
    {
      value: "2024",
      label: "2024",
    },
    {
      value: "2025",
      label: "2025",
    },
    {
      value: "2026",
      label: "2026",
    },
    {
      value: "2027",
      label: "2027",
    },
    {
      value: "2028",
      label: "2028",
    },
    {
      value: "2029",
      label: "2029",
    },
    {
      value: "2030",
      label: "2030",
    },
    {
      value: "2031",
      label: "2031",
    },
    {
      value: "2032",
      label: "2032",
    }
     ];

  const [paymentCard, setPaymentCard] = useState({
    // name: "",
    amount: "",
    currency: "USD"
    // card_number: "",
    // exp_month: "",
    // exp_year: "",
    // cvv: "",
  });

    const handleRedirect = (amount,currency) => {
      window.location.href = `https://stripe.liveassignment.com/create-checkout-session.php?amount=${amount}&currency=${currency}`;
    };
  


  const OnClickPayment = () => {

    // const cardfilter = paymentCard.card_number.replace(/\s+/g, ' ');

    console.log("res",{
      // name: paymentCard.name,
      amount: paymentCard.amount,
      currency: paymentCard.currency,
      // card_number: cardfilter,
      // exp_month: paymentCard.exp_month,
      // exp_year: paymentCard.exp_year,
      // cvv: paymentCard.cvv,
    })
    axios
      .post(
        "https://stripe.liveassignment.com/create-checkout-session.php",
          {
            // name: paymentCard.name,
            amount: paymentCard.amount,
            currency: paymentCard.currency
            // card_number: cardfilter,
            // exp_month: paymentCard.exp_month,
            // exp_year: paymentCard.exp_year,
            // cvv: paymentCard.cvv,
          },
      )
      .then((res) => {
        console.log("res",res)
        // if (res.data === "success") {
        //   setPaymentCard({
        //     name: "",
        //     amount: "",
        //     currency: "",
        //     card_number: "",
        //     exp_month: "",
        //     exp_year: "",
        //     cvv: "",
        //   });
        // }
      });
  };

  return (
    <Wrapper ishomepage={ishomepage}>
      <Container>
        <Inner>
          <Typography variant="h4" component="h2">
            Payment details
          </Typography>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6}>
              <TextField
                id="outlined-disabled"
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "NAME ON CARD",
                }}
                value={paymentCard.name}
                label="NAME ON CARD"
                placeholder="John Smith"
                onChange={(e) =>
                  setPaymentCard({
                    ...paymentCard,
                    name: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-disabled"
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "CARD NUMBER",
                }}
                type="tel"
                pattern="[0-9\.]+"
                inputProps={{maxLength :19}}
                value={paymentCard.card_number}
                label="CARD NUMBER"
                placeholder="4545 1234 1234 1234"
                onChange={(e) => {
                  let len =  e.target.value.replace(/\s/g, '').length;
                  if(len>0){
                    if(len%4 === 0){
                    e.target.value+=" "
                  }
                 }
                  setPaymentCard({
                    ...paymentCard,
                    card_number: e.target.value,
                  })
                }
                }
              />
            </Grid> */}

            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-disabled"
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "AMOUNT",
                }}
                value={paymentCard.amount}
                required
                label="AMOUNT"
                placeholder="00.00"
                onChange={(e) =>
                  setPaymentCard({
                    ...paymentCard,
                    amount: e.target.value,
                  })
                }
                
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-select-currency"
                select
                label="CURRENCEY"
                required
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "CURRENCEY",

                }}
                value={paymentCard.currency}
                onChange={(e) =>
                  setPaymentCard({
                    ...paymentCard,
                    currency: e.target.value,
                  })
                }
                
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* <Grid item xs={12} md={4}>
              <TextField
                id="outlined-select-currency"
                select
                label="EXPIRY MONTH"
                value={paymentCard.exp_month}
                onChange={(e) =>
                  setPaymentCard({
                    ...paymentCard,
                    exp_month: e.target.value,
                  })
                }
              >
                {exmonths.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-select-currency"
                select
                label="EXPIRY YEAR"
                value={paymentCard.exp_year}
                onChange={(e) =>
                  setPaymentCard({
                    ...paymentCard,
                    exp_year: e.target.value,
                  })
                }
              >
                {exyear.map((option, i) => (
                   <MenuItem key={i} value={option.value}>
                   {option.label}
                 </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-disabled"
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "CVV",
                }}
                inputProps={{maxLength :3}}
                label="CVV"
                value={paymentCard.cvv}
                placeholder="123"
                onChange={(e) =>
                  setPaymentCard({
                    ...paymentCard,
                    cvv: e.target.value,
                  })
                }
              ></TextField>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <ActionContainer>
                <Button variant="contained" color="primary" onClick={() => handleRedirect(paymentCard.amount,paymentCard.currency)}>
                  Pay Now
                </Button>
                <Typography>
                  <strong>OR</strong>
                </Typography>
                <Button variant="contained" color="secondary">
                  Pay with Paypal
                </Button>
              </ActionContainer>
            </Grid>
          </Grid>
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default PaymentForm;

const Wrapper = styled.div`
  padding: 70px 0;
  text-align: center;
  border-radius: 0 0 50px 0;

  @media (max-width: 992px) {
    padding: 50px 0;
  }

  h2.MuiTypography-root {
    font-weight: 600;
    font-size: 22px;
    background: #43a759;
    padding: 15px;
    color: #fff;
    margin: -40px -40px 50px;
    border-radius: 10px 10px 0 0;

    @media (max-width: 992px) {
      font-size: 24px;
      margin: -30px -30px 30px;
    }

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
`;

const Inner = styled.div`
  background: rgb(33 165 167 / 2%);
  padding: 40px;
  border-radius: 10px;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 0 1px 10px rgb(0 0 0 / 10%);

  @media (max-width: 992px) {
    padding: 30px;
  }

  .MuiTextField-root {
    width: 100%;
    text-align: left;
  }
`;

const ActionContainer = styled.div`
  .MuiButton-root {
    min-width: 300px !important;
  }
  .MuiTypography-root {
    margin: 15px 0;
  }
`;
