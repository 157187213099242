import styled from "styled-components";

const Container = ({children}) => (
    <ContainerBlock>
      {children}
    </ContainerBlock>
  );
  
  export default Container;

  const ContainerBlock = styled.div`
    max-width: 1270px;
    margin: 0 auto;
    width: 100%;
    padding: 0 15px;
  `