import { Container, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import InnerBanner from "../presentation/InnerBanner";
import WhyChooseUsImg from "../../assets/images/why-choose-us.jpg";
import AboutImage from "../../assets/images/about2.jpg";
import HowItWork from "../components/HowItWork";

const AboutUs = () => (
  <>
    <InnerBanner pageTitle="About us"></InnerBanner>
    <Wrapper>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ImageContainer>
              <img src={WhyChooseUsImg} alt="banner-img" />
            </ImageContainer>
          </Grid>
          <Content item xs={12} md={6}>
            <Typography>
              Our experts are well aware with the importance of original content
              so they emphasize on originality in their work that you can use
              without hesitation.
            </Typography>
            <Typography>
              Our online tutors work hard to ensure that you would get best
              homework and assignment guidance which you would expect from your
              class tutors. Our service begins right from your first query and
              goes up until where you would not learn to solve questions
              yourself.
            </Typography>
            <KeyFeature>
              <Typography variant="h6" component="h3">
                <strong>KEY FEATURES</strong>
              </Typography>
              <ul>
                <li>
                  All types of assignment, projects, and presentations with
                  guaranteed results.
                </li>
                <li>Plagiarism free assignments.</li>
                <li>
                  24*7 service to all the corners of world, and real time
                  solution.
                </li>
                <li>On – Time Service</li>
                <li>Affordable and reasonable price that save your cost</li>
                <li>
                  Used highly qualitative content that helps you to get good
                  marks
                </li>
                <li>
                  Committed employees that are able to deliver your work within
                  the determined deadlines
                </li>
              </ul>
            </KeyFeature>
          </Content>
          <Content item xs={12} md={6}>
            <Typography>
              Our experts are well aware with the importance of original content
              so they emphasize on originality in their work that you can use
              without hesitation.
            </Typography>
            <Typography>
              Our online tutors work hard to ensure that you would get best
              homework and assignment guidance which you would expect from your
              class tutors. Our service begins right from your first query and
              goes up until where you would not learn to solve questions
              yourself.
            </Typography>
            <Typography>
              Our experts are well aware with the importance of original content
              so they emphasize on originality in their work that you can use
              without hesitation.
            </Typography>
            <Typography>
              Our online tutors work hard to ensure that you would get best
              homework and assignment guidance which you would expect from your
              class tutors. Our service begins right from your first query and
              goes up until where you would not learn to solve questions
              yourself.
            </Typography>
            <Typography>
              Our online tutors work hard to ensure that you would get best
              homework and assignment guidance which you would expect from your
              class tutors. 
            </Typography>
          </Content>
          <Grid item xs={12} md={6}>
            <ImageContainer>
              <img src={AboutImage} alt="banner-img" />
            </ImageContainer>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
    <HowItWork />
  </>
);

export default AboutUs;

const Wrapper = styled.div`
  padding: 70px 0;
  position: relative;

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  .MuiButton-root {
    margin-top: 30px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  padding: 0 30px 30px 0;
  z-index: 2;
  display: flex;

  &:before {
    width: 80%;
    height: 90%;
    right: 0;
    bottom: 0;
    content: "";
    background: #1f2a622e;
    border-radius: 10px;
    position: absolute;
    z-index: -1;
  }

  img {
    border-radius: 10px;
    width: 100%;
  }
`;

const Content = styled(Grid)`
  .MuiTypography-root {
    margin-bottom: 20px;
  }
`;

const KeyFeature = styled.div`
  padding: 15px;
  background: rgb(33 165 167 / 10%);

  h3 {
    color: #21a5a7;
  }

  ul {
    li {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
`;
