import styled from "styled-components";
import {
  Avatar,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Container from "../presentation/Container";
import logoImg from "../../assets/images/logo.png";
import Applynow from "../../assets/images/completed-task.png";

const Footer = () => (
  <>
    <FooterTop>
      <Container>
        <Icon>
          <img src={Applynow} alt="Applynow" />
        </Icon>
        <Typography component="h2" variant="h4">
          <strong>
            Apply for a complete for your Assignment, <br /> homework or
            templete
          </strong>
        </Typography>
      </Container>
    </FooterTop>
    <FooterMain>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={4}>
            <Logo>
              <img src={logoImg} alt="logo" />
            </Logo>
            <List>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      <a href="mailto:support@liveassignment.com">
                        support@liveassignment.com
                      </a>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocalPhoneIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      <a href="tel:+108736726782">+10 873 672 6782</a>
                    </>
                  }
                />
              </ListItem>
            </List>
            <SocialList>
              <Link href="https://www.facebook.com/profile.php?id=61555374435654">
                <Avatar>
                  <FacebookIcon />
                </Avatar>
              </Link>
              <Link href="#">
                <Avatar>
                  <GoogleIcon />
                </Avatar>
              </Link>
              <Link href="#">
                <Avatar>
                  <TwitterIcon />
                </Avatar>
              </Link>
              <Link href="https://www.instagram.com/live.assignment?igsh=cGFkbDNtN2sxbXVs">
                <Avatar>
                  <InstagramIcon />
                </Avatar>
              </Link>
            </SocialList>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6">Quick Links</Typography>
            <List>
              <ListItem>
                <Link href="/assignment-help">Assignment Help</Link>
              </ListItem>
              <ListItem>
                <Link href="/faq">Faqs</Link>
              </ListItem>
              <ListItem>
                <Link href="/contact-us">Contact us</Link>
              </ListItem>
              <ListItem>
                <Link href="/universities">Universities</Link>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6">Useful Links</Typography>
            <List>
              <ListItem>
                <Link href="/about-us">About us</Link>
              </ListItem>
              <ListItem>
                <Link href="privacy-policy">Privacy Policy</Link>
              </ListItem>
              <ListItem>
                <Link href="/terms-conditions">Terms of service</Link>
              </ListItem>
              <ListItem>
                <Link href="/refund-policies">Refund and cancellation policy</Link>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <FooterBottom>
              <Typography>
                Copyright © LIVE ASSIGNMENT . All Rights Reserved.
              </Typography>
              <Typography component="span">
                <strong>Disclaimer:</strong> We provide assignment and homework
                help for guidance and reference purpose only. These papers are
                not to be submitted as it is. These papers are intended to be
                used for research and reference purposes only
              </Typography>
            </FooterBottom>
          </Grid>
        </Grid>
      </Container>
    </FooterMain>
  </>
);

export default Footer;

const FooterTop = styled.div`
  text-align: center;
  background: #21a5a7;
  padding: 40px 0;
  border-radius: 30px 30px 0 0;

  .MuiTypography-root {
    color: #fff;

    @media (max-width: 992px) {
      font-size: 30px;
    }

    @media (max-width: 768px) {
      font-size: 24px;

      br {
        display: none;
      }
    }
  }
`;

const FooterMain = styled.footer`
  padding: 50px 0;
  background-color: #1f2a62;

  .MuiListItem-root {
    padding: 2px 0;
    color: #fff;

    .MuiListItemIcon-root {
      color: #fff;
      padding-right: 10px;
      min-width: auto;
      font-size: 20px !important;
    }

    a {
      color: #fff;
      font-size: 14px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h6 {
    color: #fff;
    margin-bottom: 15px;

    & + .MuiList-root {
      li {
        margin-bottom: 15px;
      }
    }
  }
`;

const Logo = styled(Link)`
  margin-bottom: 20px;
  display: inline-block;

  img {
    height: 60px;
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  padding: 15px;
  background: rgb(33 165 167 / 22%);
  border-radius: 15px;

  .MuiTypography-root {
    font-size: 14px;
    color: #fff;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  span.MuiTypography-root {
    font-size: 12px;
    display: block;
    line-height: 18px;
  }
`;

const Icon = styled.div`
  margin-bottom: 20px;

  img {
    max-height: 80px;
    @media (max-width: 992px) {
      max-height: 50px;
    }
  }
`;

const SocialList = styled.div`
  display: flex;
  margin-top: 15px;

  .MuiAvatar-root {
    background: #21a5a7;
    margin-right: 10px;
  }
`;
