import { Avatar, Rating, Typography } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import Container from "../presentation/Container";
import Heading from "../presentation/Heading";
import "swiper/css";
import "swiper/css/pagination";
import AuthorImg from "../../assets/images/author.png";

const Testimonials = () => {
  const [value, setValue] = useState(5);
  return (
    <Wrapper>
      <Container>
        <Heading
          centered={true}
          title={
            <>
              Dont take our word for it. See what <br /> customers are saying
              about us.
            </>
          }
        />
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
        >
          <SwiperSlide>
            <Item>
              <Rating name="read-only" value={value} readOnly />
              <Typography>
                “Thanks Live Assignment for making my business presentation in
                very effective manner. You guys saved my job and boss is really
                happy with my presentation too. Thanks a lot”
              </Typography>
              <AvatarContainer>
                <Avatar
                  alt="Vikram Sinha"
                  src={AuthorImg}
                  sx={{ width: 60, height: 60 }}
                />
                <Typography>Vikram Sinha</Typography>
              </AvatarContainer>
            </Item>            
          </SwiperSlide>
          <SwiperSlide>
            <Item>
              <Rating name="read-only" value={value} readOnly />
              <Typography>
              “Your work is really appreciated. Keep growing. Thanks.”
              </Typography>
              <AvatarContainer>
                <Avatar
                  alt="Sophie"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 60, height: 60 }}
                />
                <Typography>Sophie</Typography>
              </AvatarContainer>
            </Item>            
          </SwiperSlide>
          <SwiperSlide>
            <Item>
              <Rating name="read-only" value={value} readOnly />
              <Typography>
                “Thanks Live Assignment for making my business presentation in
                very effective manner. You guys saved my job and boss is really
                happy with my presentation too. Thanks a lot”
              </Typography>
              <AvatarContainer>
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 60, height: 60 }}
                />
                <Typography>Vikram Sinha</Typography>
              </AvatarContainer>
            </Item>            
          </SwiperSlide>
          <SwiperSlide>
            <Item>
              <Rating name="read-only" value={value} readOnly />
              <Typography>
                “Thanks Live Assignment for making my business presentation in
                very effective manner. You guys saved my job and boss is really
                happy with my presentation too. Thanks a lot”
              </Typography>
              <AvatarContainer>
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 60, height: 60 }}
                />
                <Typography>Vikram Sinha</Typography>
              </AvatarContainer>
            </Item>            
          </SwiperSlide>
        </Swiper>
      </Container>
    </Wrapper>
  );
};

export default Testimonials;

const Wrapper = styled.div`
  padding: 70px 0;
  position: relative;
  background: #f7f9fb;

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  .swiper-wrapper {
    height: inherit;

    .swiper-slide {
      height: inherit;
    }
  }
`;

const Item = styled.div`
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .MuiRating-root {
    margin-bottom: 15px;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-top: auto;

  .MuiTypography-root {
    padding-left: 15px;
    font-weight: 600;
    color: #1f2a62;
  }
`;
