
import InnerBanner from "../presentation/InnerBanner";
import PaymentCanceled from "../components/PaymentCanceled"  
import Testimonials from "../components/Testimonials";

const PaymentCancel = () => (
  <>
    <InnerBanner pageTitle="Payment cancel"></InnerBanner>
    <PaymentCanceled/>
    <Testimonials/>
    
  </>
);

export default PaymentCancel;