import Faqs from "../components/Faqs";
import InnerBanner from "../presentation/InnerBanner";

const Faq = () => (
  <>
    <InnerBanner pageTitle="FAQ's"></InnerBanner>
    <Faqs />
  </>
);

export default Faq;
