import Header from "./Header";
import Footer from "./Footer";
import QuickConnect from "./QuickConnect";
import { Outlet } from "react-router-dom";


const Layout = () => (
    <>
    <Header/>
    <QuickConnect/>
    <Outlet/>
    <Footer />
    </>
  );
  
  export default Layout;