import { Button, ButtonGroup, Link } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import styled from "styled-components";

const QuickConnect = () => (
  <QuickConnectContainer
    variant="contained"
    aria-label="outlined primary button group"
  >
    <Button variant="success" component={Link} href="https://wa.me/6468806222" target="_blank">
      <WhatsAppIcon />
    </Button>
    <Button component={Link} href="tel:+61480022295">
      <LocalPhoneIcon />
    </Button>
  </QuickConnectContainer>
);

export default QuickConnect;

const QuickConnectContainer = styled(ButtonGroup)`
  flex-direction: column;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 99;

  .MuiButton-root {
    width: 56px;
    padding: 0;
    min-width: 56px !important;
    border-radius: 0 !important;

    &.MuiButton-success {
      background: #3ebd4e !important;
      color: #fff !important;
    }
  }
`;
