import styled from "styled-components";
import Container from "./Container";

const Cms = ({ children }) => (
  <Section>
    <Container>
      <Content>{children}</Content>
    </Container>
  </Section>
);

export default Cms;

const Section = styled.div`
  border-radius: 20px;
  padding-bottom: 70px;
`;

const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px;
  border-radius: 20px;
  margin-top: -100px;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 10px rgb(0 0 0 / 10%);

  ul {
    li {
      margin-bottom: 15px;
    }
  }

  h3 {
    font-size: 32px;
    font-weight: 600;
    color: #1f2a62;
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: #1f2a62;
  }

  .MuiTypography-root {
    margin-bottom: 15px;
  }

  @media (max-width: 992px) {
    padding: 30px;
    margin-top: -70px;

    ul {
      li {
        font-size: 14px;
      }
    }
  }
`;
