import ContactForm from "../components/ContactForm";
import InnerBanner from "../presentation/InnerBanner";

const ContactUs = () => (
  <>
    <InnerBanner pageTitle="Contact Us"></InnerBanner>
    <ContactForm />
  </>
);

export default ContactUs;
