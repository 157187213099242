import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import Container from "../presentation/Container";

const AssignmentForm = ({ ishomepage }) => {
  const currencies = [
    {
      value: "Science",
      label: "Science",
    },
    {
      value: "Math",
      label: "Math",
    },
  ];

  return (
    <Wrapper ishomepage={ishomepage}>
      <Container>
        <Typography variant="h4" component="h2">
          How much do you want?
        </Typography>
        <Inner>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-disabled"
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "name",
                }}
                label="Name"
                placeholder="Mark John"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-disabled"
                InputLabelProps={{
                  shrink: true,
                  htmlFor: "Email",
                }}
                label="Email"
                placeholder="example@gmail.com"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Select Subject"
                defaultValue="Science"
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="contained" fullWidth color="primary">
                Continue
              </Button>
            </Grid>
          </Grid>
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default AssignmentForm;

const Wrapper = styled.div`
  ${({ ishomepage }) => css`
    background: #17204c;
    padding: ${ishomepage ? "80px 0 30px 0" : "30px 0"};
    margin-top: ${ishomepage ? "-50px" : "0"};
    text-align: center;
    border-radius: 0 0 50px 0;

    .MuiTypography-root {
      font-weight: 600;
      font-size: 32px;
      color: #fff;

      @media (max-width: 992px) {
        font-size: 24px;
      }
       
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
  `}
`;

const Inner = styled.div`
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  max-width: 950px;
  margin: 20px auto 0;
  box-shadow: 0 1px 10px rgb(0 0 0 / 5%);

  @media (max-width: 992px) {
    padding: 30px;
  }

  .MuiTextField-root {
    width: 100%;
    text-align: left;
  }
`;
