
import InnerBanner from "../presentation/InnerBanner";
import PaymentRecived from "../components/PaymentRecived"  
import Testimonials from "../components/Testimonials";


const PaymentSuccess = () => (
  <>
    <InnerBanner pageTitle="Payment Confirmation"></InnerBanner>
    <PaymentRecived/>
    <Testimonials/>
  </>
);

export default PaymentSuccess;