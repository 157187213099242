import { Typography } from "@mui/material";
import styled from "styled-components";
import Container from "../presentation/Container";

const PageNotFound = () => (
  <>
    <Wrapper>
      <Container>
        <Typography variant="h6">Comming soon...</Typography>
      </Container>
    </Wrapper>
  </>
);

export default PageNotFound;

const Wrapper = styled.div`
  padding: 70px 0;
  position: relative;
  min-height: 40vh;

  @media (max-width: 992px) {
    padding: 40px 0;
  }
`;
