import { Button, Grid, Hidden, Typography } from "@mui/material";
import styled from "styled-components";
import Container from "../presentation/Container";
import BannerImg from "../../assets/images/banner-img.png";

const Banner = () => (
  <Wrapper>
    <Container>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={7}>
          <Typography variant="h2" component="h1">
            Get <span> Assignment Help</span> From <br /> Best Assignment Help{" "}
            <span>Company Instantly!!</span>
          </Typography>
          <Typography>
            We provide homework assistance in all major subjects for one and all
            problems.
          </Typography>
          <Button variant="contained" color="primary">
            Assignment Help
          </Button>
        </Grid>
        <Hidden mdDown>
        <Grid item xs={5}>
          <img src={BannerImg} alt="banner-img" />
        </Grid>
        </Hidden>
      </Grid>
    </Container>
  </Wrapper>
);

export default Banner;

const Wrapper = styled.div`
  background: rgb(31 42 98);
  padding: 80px 0;
  border-radius: 0 0 50px 0;
  position: relative;

  @media (max-width: 992px) {
    padding: 30px 0 40px 0;
  }

  h1 {
    font-size: 52px;
    font-weight: 600;
    margin-bottom: 20px;

    @media (max-width: 1300px) {
      font-size: 42px;
    }

    @media (max-width: 992px) {
      font-size: 34px;

      br {
        display: none;
      }
    }

    @media (max-width: 992px) {
      font-size: 24px;
    }

    span {
      color: #21a5a7;
    }
  }

  .MuiTypography-root {
    color: #fff;
  }

  .MuiButton-root {
    margin-top: 30px;
  }
`;
