import { Typography } from "@mui/material";
import Cms from "../presentation/Cms";
import InnerBanner from "../presentation/InnerBanner";

const PrivacyPolicy = () => (
  <>
    <InnerBanner iscms={true} pageTitle="Privacy Policy"></InnerBanner>
    <Cms>
      <Typography>
        <strong>
          Our company respects the privacy of our clients/students as their
          privacy is our priority. The privacy of every user who visits our
          website either for query or for availing of our services is protected
          in the best possible manner. While collecting your personal details we
          ensure that we do not use them for any hidden purpose and we strictly
          adhere to the privacy and confidentially laws. The information about
          the collection, use, and sharing of personal information is given
          below:
        </strong>
      </Typography>
      <ul>
        <li>
          All the personal information like name, contact details, educational
          details, etc which is collected by us is kept highly confidential.
        </li>
        <li>
          We do not provide personal information like the name, contact details,
          etc of our writers to any third party for ensuring their privacy.
        </li>
        <li>
          We are highly committed to our client's privacy and for this sharing,
          any information of our client with a third party is strictly
          prohibited at our organization.
        </li>

        <li>
          The personal information of clients is solely collected for
          verification of the identity of the person requesting availing our
          services.
        </li>
        <li>
          To ensure the security and confidentiality of payment transactions of
          our clients we use secured payment gateways so that the payment is
          fast and highly secured. We provide various payment options, like
          debit cards, credit cards, mobile apps, and others which are highly
          secured.
        </li>
        <li>
          We are obliged to disclose your personal information as per the
          requirements of law only as it is our duty to comply with the relevant
          laws and legislation.
        </li>
        <li>
          While sending emails to you we strictly ensure that your Email ID is
          not shared with anyone else.
        </li>
      </ul>
    </Cms>
  </>
);

export default PrivacyPolicy;
